/** @format */

//const qParams = new URL(document.location.toString()).searchParams;

const roomByRoomRouter = [
  {
    path: "room-by-room",
    name: "RoomByRoomIntroduction",
    component: () => import("@/views/RoomByRoom/Introduction.vue"),
  },
  {
    path: "room-by-room/new/landing-page",
    name: "RoomByRoomLandingPage",
    component: () => import("@/views/RoomByRoom/LandingPage.vue"),
    meta: {
      layout: "default",
    },
  },
  {
    path: "room-by-room/new/introduction",
    name: "RoomByRoomIntroductionNew",
    component: () => import("@/views/RoomByRoom/IntroductionNew.vue"),
  },
  {
    path: "room-by-room/samsung/introduction",
    name: "SSRoomByRoomIntroduction",
    component: () =>
      import("@/views/RoomByRoom/demo/samsung/SSIntroduction.vue"),
    meta: {
      layout: "samsung",
    },
  },
  {
    path: "room-by-room/b-grid/step-3",
    name: "SamooOfficeStep3",
    component: () => import("@/views/RoomByRoom/demo/samoo_office/Step3.vue"),
    meta: {
      layout: "samoo_office",
    },
  },
  {
    path: "room-by-room/samsung/furniture-by-room",
    name: "SSFurnitureByRoom",
    component: () =>
      import("@/views/RoomByRoom/demo/samsung/SSFurnitureByRoom.vue"),
    meta: {
      layout: "samsung",
    },
  },
  {
    path: "room-by-room/vinhomes-villas",
    name: "VinhomeVillasDemo",
    component: () => import("@/views/RoomByRoom/VinhomeVillasDemo.vue"),
  },
  {
    path: "room-by-room/new/:id",
    name: "RoomByRoomNew",
    component: () => import("@/views/RoomByRoom/RoomByRoomNew.vue"),
  },
  {
    path: "room-by-room/new/:pro_name",
    name: "RoomByRoomLandingPage",
    component: () => import("@/views/RoomByRoom/LandingPage.vue"),
  },
  {
    path: "room-by-room/project/:pro_name",
    name: "ClientIntroduction",
    component: () => import("@/views/RoomByRoom/ClientIntroduction.vue"),
  },
  {
    path: "room-by-room/:id",
    name: "RoomByRoomMain",
    component: () => import("@/views/RoomByRoom/RoomByRoomMain.vue"),
  },
  {
    path: "room-by-room/:pro_name/:id",
    name: "RoomByRoomMain",
    component: () => import("@/views/RoomByRoom/RoomByRoomMain.vue"),
  },
  {
    path: "rbr-style-checking/:id",
    name: "RoomByRoomMain",
    component: () => import("@/views/RoomByRoom/RoomByRoomMain.vue"),
  },
  {
    path: "room-by-room-vinhomes",
    name: "RoomByRoomLandingPage",
    component: () => import("@/views/RoomByRoom/LandingPageVinhomes.vue"),
  },
];
export default roomByRoomRouter;
